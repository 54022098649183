import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    /* Colors palette */
    /* from https://www.happyhues.co/palettes/3 */
    --white: #fffffe;
    --blackBlue: #094067;
    --darkGray: #5f6c7b;
    --blue: #3da9fc;
    --red: #ef4565;


    /* Colors roles */
    --bodyBackground: var(--white);

    --background: var(--blackBlue);
    --textColor: var(--white);
    --linkColor: var(--white);
    --linkUnderlineColor: var(--red);

    --headerColor: var(--white);
    --headerBgColor: var(--blackBlue);

    /* Elevation */
      --level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
      --level-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
      --level-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.5);
      --level-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);

    /* from type-scale.com */
    /* Typography */
    --bodyFont: 'Open Sans', sans-serif;
    --headingFont: 'Francois One', sans-serif;
    --fontSize: 125%; 
    /* 20px */

    /* headings sizes - default */
      --h1: 3.052rem;
      --h2: 2.441rem;
      --h3: 1.953rem;
      --h4: 1.5637rem;
      --h5: 1.25rem;
      --smallText: 0.8rem;
    
      /* only for small screens */
      @media screen and (max-width: 768px){
      --h1: 2rem;
     }

    --linkUnderlineThick: 0.09em;

    /* containers */
    --containerPaddingLR: 1rem;
    --containerMarginLR: auto;
    --containerWidth: 100%;

    /* @media screen and (min-width: 900px){
      --containerWidth: calc(900px - 2 * var(--containerPaddingLR));
    } */
    @media screen and (min-width: 576px){
      --containerWidth: calc(100% - 1.5rem);
     }
    @media screen and (min-width: 1400px){
      --containerWidth: calc(1400px - 2 * var(--containerPaddingLR));
    }
  }

  html {
    box-sizing: border-box;
    font-size: var(--fontSize);
  }

  *,
  *::before,
  *::after{
    box-sizing: inherit;
  }

  body {
    background: var(--bodyBackground);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
  }

  p {margin-bottom: 1rem;}

  h1, h2, h3, h4, h5, 
  .h1Like, .h2Like, .h3Like, .h4Like, .h5Like {
    font-family: var(--headingFont);
    /* margin: 3rem 0 1.38rem; */
    margin: 0;
    font-weight: 400;
    line-height: 1.3;
  }

  h1, .h1Like {
    margin-top: 0;
    font-size: var(--h1);
  }

  h2, .h2Like {font-size: var(--h2)}

  h3, .h3Like {font-size: var(--h3)}

  h4, .h4Like {font-size: var(--h4)}

  h5, .h5Like {font-size: var(--h4)}

  small, .text_small {font-size: var(--smallText)}

  a{
    color: var(--linkColor);
    text-decoration: none;
    border-bottom: var(--linkUnderlineThick) solid var(--linkUnderlineColor);

    :hover{
      /* color: var(--linkUnderlineColor); */
      border-bottom-color: var(--linkColor);
    }
  }

  .container{
    overflow-wrap: break-word;

  }

  .container, 
  .container--fullWidth > :not(.container)
  {
    padding: 0 var(--containerPaddingLR);
  }

  .container--fullWidth > :not(.container){
    /* color: red; */
  }

  .container--fullWidth > .container--fullWidth > * {
    /* color: greenyellow; */
    padding-left: 0;
    padding-right: 0;
  }

  .container .container{
    margin-left: calc( -1 * var(--containerPaddingLR) );
    margin-right: calc( -1 * var(--containerPaddingLR) );
  }

  .container{
    width: 100%;
    max-width: var(--containerWidth);
    margin: 0 auto;
  }

  `;

export default GlobalStyles;
