import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <h1 className="container">{siteTitle}</h1>
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

const StyledHeader = styled.header`
  background: var(--headerBgColor);
  color: var(--headerColor);
  margin-bottom: 1.45rem;
`;
